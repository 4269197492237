<template>
   <div>
       <header-title :title="title"></header-title>
        <rs-form :model="formData" :show-message="false" label-width="156px" size="small" :rules="rules" ref="formData" class="form-70 commom-form">
            <rs-row>  
                <rs-col :span="12">
                    <rs-form-item label="岗位名称" :maxlength="20" prop="stationName">
                        <rs-input v-model="formData.stationName" placeholder="请输入"></rs-input>
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="选择组织" prop="organizationSysCode">
                        <org-select :orgSelectName="formData.organizationName" :organizationSysCode="formData.organizationSysCode" @orgSelectChange="orgSelectChange"></org-select>
                    </rs-form-item>
                </rs-col>
            </rs-row>
            <rs-row> 
                <rs-col :span="12">
                    <rs-form-item label="岗位描述">
                        <rs-input v-model="formData.remark" show-word-limitp type="textarea" maxlength="100" :rows="5" placeholder="请输入"></rs-input>
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="岗位状态" prop="status">
                        <rs-select
                            v-model="formData.status"
                            placeholder="选择状态">
                            <rs-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </rs-option>
                        </rs-select>
                    </rs-form-item>
                </rs-col>
            </rs-row>
        </rs-form>
        <div class="edit-form button-box" style="text-align: right">
            <rs-button size="small"  @click="jumpBack(false)">取消</rs-button>
            <rs-button size="small" @click="save" type="primary" :loading="saveLoading">保存</rs-button>
        </div>
    </div>
</template>
<script>
    import Api from '@/api/organizationApi'
    import Utils from '@/utils/utils'
    import orgSelect from '@/components/orgSelect'
    import headerTitle from '@/components/header'
    export default {
        components: {
            orgSelect,
            headerTitle
        },
        computed:{
        },
        data() {
            return {
                formData: {
                    companyCode: Utils.getSession("userInfo").companyVo.companyCode,
                    sort: '',
                    remark: '',
                    organizationName: '',
                    organizationSysCode: '',
                    stationName: '',
                    status: true
                },
                rules: {
                    stationName: [
                        {required: true, message: '请输入岗位名称', trigger: 'change'},
                        { max: 20, message: '最多20个字符', trigger: 'change' }
                    ],
                    organizationSysCode: [{required: true, message: '请选择组织', trigger: 'change'}],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}]
                },
                statusOptions: [
                    {label: '已启用', value: true},
                    {label: '已停用', value: false},
                ],
                treeParams: {
                    clickParent: true,
                    filterable: true,
                    'check-strictly': true,
                    'default-expand-all': true,
                    'expand-on-click-node': false,
                    // 'check-on-click-node': true,
                    'highlight-current': true,
                    // 'show-checkbox': true,
                    data: [],
                    props: {
                        children: 'children',
                        label: 'orgName',
                        disabled: 'disabled',
                        value: 'orgSysCode'
                    }
                },
                userId: '',
                saveLoading: false,
                title: ''
            }
        },
        async created() {
            this.title = '新建岗位'
            if(this.$route.query.id) {
                this.title = '编辑岗位'
                this.userId = this.$route.query.id
                await this.handleDetail()
            } 
        },
        methods: {
            orgSelectChange(val) {
                this.formData.organizationSysCode = val.value
                this.formData.organizationName = val.orgName
            },
            validate(cb) {
                this.$refs['form'].validate(cb);
            },
            resetFields() {
                this.$refs.form.resetFields();
            },
            // 获取详情
            async handleDetail() {
                let user = Utils.getSession("userInfo");
                let res = await Api.queryStationDetail({stataionId: this.userId})
                this.formData = {
                    companyCode: user.companyVo.companyCode,
                    sort: res.data.data.sort?res.data.data.sort:'',
                    remark: res.data.data.remark,
                    organizationName: res.data.data.organizationName,
                    organizationSysCode: res.data.data.organizationSysCode,
                    stationName: res.data.data.stationName,
                    status: res.data.data.status,
                    organizationName: res.data.data.organizationName,
                    stationId: this.userId
                }
            },
            // 弹框确认新增
            save() {
                this.$refs['formData'].validate((valid) => {
                    if (valid) {
                        this.saveLoading = true
                        Api.saveStation(this.formData).then(res=>{
                            this.saveLoading = false
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.jumpBack()
                        }).catch(err=>{
                           this.saveLoading = false
                       })
                    } else {
                        top.$message.warning('请填写基本信息')
                    }
                });
            },
            jumpBack() {
                this.$router.back()
            },
        }
    }
    
</script>
<style lang="less" scoped>
.demo-ruleForm {
    width: 400px;
}
</style>