import { render, staticRenderFns } from "./stationEdit.vue?vue&type=template&id=b21b293e&scoped=true"
import script from "./stationEdit.vue?vue&type=script&lang=js"
export * from "./stationEdit.vue?vue&type=script&lang=js"
import style0 from "./stationEdit.vue?vue&type=style&index=0&id=b21b293e&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b21b293e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\西迪港\\项目\\web\\user-h5-xdg\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b21b293e')) {
      api.createRecord('b21b293e', component.options)
    } else {
      api.reload('b21b293e', component.options)
    }
    module.hot.accept("./stationEdit.vue?vue&type=template&id=b21b293e&scoped=true", function () {
      api.rerender('b21b293e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/organizationManage/stationEdit.vue"
export default component.exports